import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/api';
import Print from 'vue-print-nb'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css'
import VueCropper from 'vue-cropper';
import $ from 'jquery';
import preventReClick from './utils/plugin'

Vue.prototype.axios = api;
Vue.config.productionTip = false;
Vue.use(ElementUI)
Vue.use(Print)
Vue.use(VueCropper)
Vue.use(preventReClick);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
