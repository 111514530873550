import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:localStorage.getItem('token') ? localStorage.getItem('token') : '',             //token标识
    user_id:localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '',       //用户id
    uploads:localStorage.getItem('uploads') ? localStorage.getItem('uploads') : '',       //文件上传地址
    user_avatar:localStorage.getItem('user_avatar') ? localStorage.getItem('user_avatar') : '',         //头像
    user_name:localStorage.getItem('user_name') ? localStorage.getItem('user_name') : '',         //名称
    cla_id:localStorage.getItem('cla_id') ? localStorage.getItem('cla_id') : -1,         //课堂id
    team_id:localStorage.getItem('team_id') ? localStorage.getItem('team_id') : '',         //小组id
    phone:localStorage.getItem('phone') ? localStorage.getItem('phone') : '',         //手机号
    tem_id:localStorage.getItem('tem_id') ? localStorage.getItem('tem_id') : '',   //教师端小组id
  },
  mutations: {
    token(state,value){
      state.token = value
      localStorage.setItem('token',value);
    },
    user_id(state,value){
      state.user_id = value
      localStorage.setItem('user_id',value);
    },
    uploads(state,value){
      state.uploads = value
      localStorage.setItem('uploads',value);
    },
    user_avatar(state,value){
      state.user_avatar = value
      localStorage.setItem('user_avatar',value);
    },
    user_name(state,value){
      state.user_name = value
      localStorage.setItem('user_name',value);
    },
    cla_id(state,value){
      state.cla_id = value
      localStorage.setItem('cla_id',value);
    },
    team_id(state,value){
      state.team_id = value
      localStorage.setItem('team_id',value);
    },
    phone(state,value){
      state.phone = value
      localStorage.setItem('phone',value);
    },
    tem_id(state,value){
      state.tem_id = value
      localStorage.setItem('tem_id',value);
    },
  },
  actions: {
    token(context,value){
      context.commit('token',value)
    },
    user_id(context,value){
      context.commit('user_id',value)
    },
    uploads(context,value){
      context.commit('uploads',value)
    },
    user_avatar(context,value){
      context.commit('user_avatar',value)
    },
    user_name(context,value){
      context.commit('user_name',value)
    },
    cla_id(context,value){
      context.commit('cla_id',value)
    },
    team_id(context,value){
      context.commit('team_id',value)
    },
    phone(context,value){
      context.commit('phone',value)
    },
    tem_id(context,value){
      context.commit('tem_id',value)
    },
  },
  modules: {},
});
