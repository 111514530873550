import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const VueRouterPush =VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path:'/',
    redirect:'/login'
  },
  // 登录login
  {
    path:'/login',
    name:'login',
    component:()=>import("@/views/login/login.vue"),
  },
  // 学生端
  {
    path:'/studentIndex',
    name:'studentIndex',
    component:()=>import("@/views/student/index.vue"),
    redirect: {
      name:'StudentPage'
    },
    children:[
      // 首页
      {
        path:'/StudentPage',
        name:'StudentPage',
        component:()=>import("@/views/student/A/StudentPage.vue"),
        meta:{requiresAuth:false,qrCode:false,info:true,session:false,}
      },
      {
        path:'/UserAnalyAll',
        name:'UserAnalyAll',
        component:()=>import("@/views/student/A/UserAnalyAll.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:false,}
      },
      // 目标用户分析
      {
        path:'/UserAnaly_A',
        name:'UserAnaly_A',
        component:()=>import("@/views/student/A/UserAnaly_A.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      // 目标用户分析
      {
        path:'/UserAnaly_B',
        name:'UserAnaly_B',
        component:()=>import("@/views/student/A/UserAnaly_B.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      // 目标用户分析
      {
        path:'/UserAnaly_C',
        name:'UserAnaly_C',
        component:()=>import("@/views/student/A/UserAnaly_C.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      //目标客户分析
      {
        path:'/CustorAnalyAll',
        name:'CustorAnalyAll',
        component:()=>import("@/views/student/A/CustorAnalyAll.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:false,}
      },
      //目标客户分析
      {
        path:'/CustorAnaly_A',
        name:'CustorAnaly_A',
        component:()=>import("@/views/student/A/CustorAnaly_A.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      //目标客户分析
      {
        path:'/CustorAnaly_B',
        name:'CustorAnaly_B',
        component:()=>import("@/views/student/A/CustorAnaly_B.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      //目标客户分析
      {
        path:'/CustorAnaly_C',
        name:'CustorAnaly_C',
        component:()=>import("@/views/student/A/CustorAnaly_C.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      //目标用户应用场景分析
      {
        path:'/Targetusers',
        name:'Targetusers',
        component:()=>import("@/views/student/A/Targetusers.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      
      //目标用户应用场景分析
      {
        path:'/TargetusersAll',
        name:'TargetusersAll',
        component:()=>import("@/views/student/A/TargetusersAll.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:false,}
      },
      //目标客户购买场景分析
      {
        path:'/Targecustomer',
        name:'Targecustomer',
        component:()=>import("@/views/student/A/Targecustomer.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      //目标客户购买场景分析
      {
        path:'/TargecustomerAll',
        name:'TargecustomerAll',
        component:()=>import("@/views/student/A/TargecustomerAll.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:false,}
      },
      // 个人中心
      {
        path:'/Personalcenter',
        name:'Personalcenter',
        component:()=>import("@/views/student/Personalcenter.vue"),
        meta:{requiresAuth:false,qrCode:false,info:false,session:false,}
      },
      
      // 用户反馈
      {
        path:'/Userfeedback',
        name:'Userfeedback',
        component:()=>import("@/views/student/Userfeedback.vue"),
        meta:{requiresAuth:false,qrCode:false,info:true,session:false,}
      },
      // 竞品分析
      {
        path:'/Competing_A',
        name:'Competing_A',
        component:()=>import("@/views/student/A/Competing_A.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      // 竞品分析
      {
        path:'/Competing_B',
        name:'Competing_B',
        component:()=>import("@/views/student/A/Competing_B.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      // 竞品分析
      {
        path:'/CompetingAll',
        name:'CompetingAll',
        component:()=>import("@/views/student/A/CompetingAll.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:false,}
      },
      // 产品规划
      {
        path:'/Productplan',
        name:'Productplan',
        component:()=>import("@/views/student/A/Productplan.vue"),
        meta:{requiresAuth:true,qrCode:true,info:true,session:false,}
      },
      // 产品规划
      {
        path:'/ProductplanAll',
        name:'ProductplanAll',
        component:()=>import("@/views/student/A/ProductplanAll.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:false,}
      },
      // B场
      // 首页
      {
        path:'/Bhomepage',
        name:'Bhomepage',
        component:()=>import("@/views/student/B/Bhomepage.vue"),
        meta:{requiresAuth:false,qrCode:false,info:true,session:true,}
      },
      // 鱼骨图
      {
        path:'/Fishbonediagram',
        name:'Fishbonediagram',
        component:()=>import("@/views/student/B/Fishbonediagram.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:true,}
      },
      // 资源脑图分析
      {
        path:'/Resourcesfigure',
        name:'Resourcesfigure',
        component:()=>import("@/views/student/B/Resourcesfigure.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:true,}
      },
      // 创智流程沙盘
      {
        path:'/Wisdomprocess',
        name:'Wisdomprocess',
        component:()=>import("@/views/student/B/Wisdomprocess.vue"),
        meta:{requiresAuth:false,qrCode:true,info:true,session:true,}
      },
    ]
  },
  //教师端
  {
    path:'/teacher',
    name:'teacher',
    component:()=>import("@/views/teacher/index.vue"),
    redirect: {
      name:'coursePage'
    },
    children:[
      // 课程首页
      {
        path:'/coursePage',
        name:'coursePage',
        component:()=>import("@/views/teacher/coursePage.vue"),
        redirect:{
          name:'Courseinformation'
        },
        children:[
          // 课程信息——添加课程
          {
            path:'/Courseinformation',
            name:'Courseinformation',
            component:()=>import("@/views/teacher/Courseinformation.vue"),
          },
          //个人中心
          {
            path:'/Personalcenters',
            name:'Personalcenters',
            component:()=>import("@/views/teacher/Personalcenters.vue"),
          }
        ]
      },
      //课程详情
      {
        path:'/Coursedetails',
        name:'Coursedetails',
        component:()=>import("@/views/teacher/Coursedetails.vue"),
      },
    ],
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
