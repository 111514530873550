import axios from 'axios'
import {get, post, put} from '../utils/request'
axios.prototype.get = get
axios.prototype.post = post
axios.prototype.put = put
export default {
    // 学生注册-发送短信验证码
    usersmsregist(data) {
        return axios.post('user/sms/regist',data)
    },
    // 学生注册
    userregist(data) {
        return axios.post('user/regist',data)
    },
    // 忘记密码-发送短信验证码
    usersmsresetpwd(data) {
        return axios.post('user/sms/resetpwd',data)
    },
    // 忘记密码
    userresetpwd(data) {
        return axios.post('user/resetpwd',data)
    },
    // 登录
    userlogin(data) {
        return axios.post('user/login',data)
    },
    // 修改登录密码
    changepw(data) {
        return axios.post('user/changepw',data)
    },
    // 更新用户基本信息
    userupdate(data) {
        return axios.post('user/update',data)
    },
    //退出登录
    userlogout(data) {
        return axios.post('user/logout',data)
    },

    /**
        教师端
    **/
   // 获取案例列表
    TeacaseList(data) {
        return axios.post('manage/course/case/list',data)
    },
    // 获取课堂分页数据
    Teacasepage(data) {
        return axios.post('manage/course/page',data)
    },
    // 新建课堂
    Teacreate(data) {
        return axios.post('manage/course/create',data)
    },
    // 删除课堂
    TeacreateDel(data) {
        return axios.post('manage/course/del',data)
    },
    // 获取课堂资料库_列表
    TeaDocList(data) {
        return axios.post('manage/course/doc/list',data)
    },
    //课堂资料库_添加
    TeaDocAdd(data) {
        return axios.post('manage/course/doc/add',data)
    },
    //课堂资料库_删除
    TeaDocDel(data) {
        return axios.post('manage/course/doc/del',data)
    },
    // 获取课堂也详情
    Teacreatedetail(data) {
        return axios.post('manage/course/detail',data)
    },
    //获取倒计时
    coursectdGet(data) {
        return axios.post('manage/course/ctdGet',data)
    },
    //新建倒计时
    coursecctdAdd(data) {
        return axios.post('manage/course/ctdAdd',data)
    },
    //开始倒计时
    ctdStart(data) {
        return axios.post('manage/course/ctdStart',data)
    },
    // 设置开课
    Teacreatestart(data) {
        return axios.post('manage/course/start',data)
    },
    // 设置课堂步骤进行下一轮
    Teacreatesstep(data) {
        return axios.post('manage/course/sstep',data)
    },
    // 设置结课
    Teacreateend(data) {
        return axios.post('manage/course/end',data)
    },
    // 获取课堂资料库_列表
    TeaDocList(data) {
        return axios.post('manage/course/doc/list',data)
    },
    //课堂资料库_添加
    TeaDocAdd(data) {
        return axios.post('manage/course/doc/add',data)
    },
    //课堂资料库_删除
    TeaDocDel(data) {
        return axios.post('manage/course/doc/del',data)
    },


    /**
        学生端
    **/
   //获取我加入的课堂_列表
    StuCourseList(data) {
        return axios.post('course/list',data)
    },
    //通过课堂码查询课堂
    StuCoursecode(data) {
        return axios.post('course/code',data)
    },
    // 加入课堂
    StuCoursejoin(data) {
        return axios.post('course/join',data)
    },
    // 更换小组
    StuCourseteam(data) {
        return axios.post('course/team',data)
    },
    // 更换小组
    StuCoursswitchTeam(data) {
        return axios.post('course/switchTeam',data)
    },
    //删除课堂
    witchDel(data) {
        return axios.post('course/del',data)
    },
    // 获取课堂资料库_列表
    StuCoursedoc(data) {
        return axios.post('course/doc',data)
    },
    // 获取我进行中的课堂
    CourseIndex(data) {
        return axios.post('course/index',data)
    },
    // 获取课堂基本信息
    StuCourseinfo(data) {
        return axios.post('course/info',data)
    },
    // 获取课堂步骤数据
    StuCoursestep(data) {
        return axios.post('course/step',data)
    },
    // 获取课堂步骤数据
    StuCoursenext(data) {
        return axios.post('course/next',data)
    },
    // 获取结果评价
    Stugetfb(data) {
        return axios.post('course/getfb',data)
    },
    // 提交结果评价
    Stusetfb(data) {
        return axios.post('course/setfb',data)
    },
    // 获取S1数据
    projectS1P1(data) {
        return axios.post('project/get/s1/p1',data)
    },
    projectS1P2(data) {
        return axios.post('project/get/s1/p2',data)
    },
    projectS1P3(data) {
        return axios.post('project/get/s1/p3',data)
    },
    // 提交S1数据
    Sets1(data) {
        return axios.post('project/set/s1',data)
    },
    // 获取S2数据
    projectS2P1(data) {
        return axios.post('project/get/s2/p1',data)
    },
    projectS2P2(data) {
        return axios.post('project/get/s2/p2',data)
    },
    projectS2P3(data) {
        return axios.post('project/get/s2/p3',data)
    },
    // 提交S2数据
    Sets2(data) {
        return axios.post('project/set/s2',data)
    },
    // 获取S3数据
    projectS3P1(data) {
        return axios.post('project/get/s3/p1',data)
    },
    projectS3P2(data) {
        return axios.post('project/get/s3/p2',data)
    },
    projectS3P3(data) {
        return axios.post('project/get/s3/p3',data)
    },
    projectS3P4(data) {
        return axios.post('project/get/s3/p4',data)
    },
    projectS3P5(data) {
        return axios.post('project/get/s3/p5',data)
    },
    projectS3P6(data) {
        return axios.post('project/get/s3/p6',data)
    },
    // 提交S3数据
    Sets3(data) {
        return axios.post('project/set/s3',data)
    },
    // 获取S4数据
    projectS4P1(data) {
        return axios.post('project/get/s4/p1',data)
    },
    // 提交S4数据
    Sets4(data) {
        return axios.post('project/set/s4',data)
    },
    
    // 获取S5数据
    projectS5P1(data) {
        return axios.post('project/get/s5/p1',data)
    },
    projectS5P2(data) {
        return axios.post('project/get/s5/p2',data)
    },
    // 提交S5数据
    Sets5(data) {
        return axios.post('project/set/s5',data)
    },
    
    // 获取S6数据
    projectS6P1(data) {
        return axios.post('project/get/s6/p1',data)
    },
    // 提交S6数据
    Sets6(data) {
        return axios.post('project/set/s6',data)
    },
    // 获取倒计时
    StudctdGet(data) {
        return axios.post('/course/ctdGet',data)
    },
}